.bold-text {
    font-weight: 700;
}

.fixed-button {
    position: absolute;
    top: 20px;
    right: 20px;
}

.pastButton {
    background: linear-gradient(to right,rgba(167, 189, 228, 0.6), rgba(167, 189, 228, 0.6));
    border-color: rgba(167, 189, 228, 0.6);
    color: #fff;
    margin: auto;
    transition: background .2s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-transition: background .2s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -moz-transition: background .2s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -ms-transition: background .2s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -o-transition: background .2s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.pastButton:hover {
    color: #0e0e56;
    /* background: linear-gradient(to right, #dfae89, #18c0e6); */
    background-color: aliceblue;
    border-color: #cad4e0;
}

.pastButton:active {
    color: #0e0e56;
    /* background: linear-gradient(to right, #dfae89, #18c0e6); */
    background-color: aliceblue;
    border-color: #cad4e0;
}

.pastButton:focus {
    color: #0e0e56;
    /* background: linear-gradient(to right, #dfae89, #18c0e6); */
    background-color: aliceblue;
    border-color: #cad4e0;
    box-shadow: 0 0 0 0.25rem rgb(202 212 224 / 50%);
}

.pastButton:active:focus {
    box-shadow: 0 0 0 0.25rem rgb(202 212 224 / 50%);
}

.heavy-text {
    font-weight: 500;
}

.logo-img {
    width: 170px;
}

p {
    color: white;
}

.video_container {
    width: 900px;
    height: 100%;
}

.showWhenSmall {
    display: none;
}

@media screen and (max-width: 767px) {
    .logo-img {
        width: 80px;
    }
    .small-text {
        font-size: 0.8rem;
    }
    .reserve-spot {
        font-size: 1rem;
    }
    .showWhenSmall {
        display: block;
    }
    #hide-when-small {
        display: none;
    }
}

h3 {
    color: white;
}

.reserve-spot {
    color: #af0202;
    font-size: 1.4rem;
    /* font-weight: 400; */
    background: #c9dced;
    border-color: #cad4e0;
    margin-top: 4%;
    margin-bottom: 4%;
    padding-left: 2%;
    padding-right: 2%;
    margin-left: auto;
    margin-right: auto;
}

.reserve-spot:hover {
    color: #af0202;
    background: #c9dced;
    border-color: #cad4e0;
}

.reserve-spot:active {
    color: #af0202;
    background: #c9dced;
    border-color: #cad4e0;
}

.reserve-spot:focus {
    color: #af0202;
    background: #c9dced;
    border-color: #cad4e0;
}

.reserve-spot:active:focus {
    box-shadow: #cad4e0
}

.thin-divider {
    width: 93%;
    height: 2px;
    border-radius: 2px;
    background-color: rgba(255,255,255,0.2);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

.my-body {
    min-height: 100vh;
}



.bands-backdrop {
    background-color: rgba(167, 189, 228, 0.25);
    margin: 0 auto;
    width: 90vw;
    min-height: 300px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding-top: 2%;
}

.divider {
    width: 93%;
    height: 2px;
    border-radius: 2px;
    background-color: #fff;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

.thin-divider {
    width: 93%;
    height: 2px;
    border-radius: 2px;
    background-color: rgba(255,255,255,0.2);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

.sawti-ilak-2021 {
    color: #fff;
    font-weight: 500;
}

.thin-divider {
    display: none;
}

@media screen and (max-width: 767px) {
    .bands-backdrop {
        width: 100vw;
    }
    .thin-divider {
        display: block;
    }
}

.pictures-button {
    height: fit-content;
}
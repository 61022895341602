.top-nav-logo {
    cursor: pointer;
}

@media screen and (min-width: 767px) {
    #sawti-ilak-nav-link {
        display: none;
    }
    .navbar-adjustment {
        margin-left: -170px;
    }
}

.first-last-name {
    font-size: 16px;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    width: 350px;
    outline: none;
    padding: 18.5px 60px 18.5px 60px;
    transition: box-shadow ease .25s,border-color ease .25s;
    color: #495057;
    text-align: center;
}
.song-backdrop {
    background-color: rgba(255, 255, 255, 0.30);
    margin: 0vh auto 2vh auto;
    width: 90vw;
    min-height: 200px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding-top: 2%;
}

.band-image-song-page {
    object-fit: cover;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}


@media screen and (max-width: 767px) {
    .song-backdrop {
        width: 100vw;
    }
}

@media screen and (max-width: 575px) {
    .band-image-song-page {
    }
    .band-main-info {
        text-align: center;
    }
    .band-secondary-info {
        text-align: center;
    }
    .small-hidden {
        display: none;
    }
}

@media screen and (min-width: 575px) {
    .medium-hidden {
        display: none;
    }
}

.left-tab {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 0px !important;
}

.right-tab {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 20px !important;
}

.only-tab {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}

.tabs {
    border: 0 !important;
}

.tab {
    color: rgba(280,280 ,280 , 0.8)
}

.tab:hover {
    color: white
}

h2 {
    color: white;
}

h5 {
    color: white;
}

.countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10%;
}

@media screen and (max-width: 767px) {
    .countdown-item-value {
        font-size: 1.3em;
    }
    .countdown-item-label {
        font-size: 0.6em;
    }
    .countdown-splitter {
        font-size: 1.3em;
    }
    .countdown-item {
        padding: 1px;
    }
}
.judge-image {
    height: 64px;
    width: 64px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

@media screen and (max-width: 576px) {
    .judge-name {
        font-weight: 500;
        padding-bottom: 5px ;
    }
}

@media screen and (min-width: 767px) {
    .event-row:hover {
        background-color: rgba(255, 255, 255, 0.25);
        border-radius: 5px;
        cursor: pointer;
        z-index: 1;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
    }
}


.pastEventButton {
    width: 130px;
    height: 130px;
    font-size: 1.2em;
    background-color: #090938;
    background-image: 
        radial-gradient(circle at 120% 120%, #d4883c 0%, #b23641 15%, #090938 50%, transparent 50%, transparent ), 
        radial-gradient(circle at -20% -20%, #1648bd 0%, #090938 50%, transparent 50%, transparent );
    border-color: #090938;
    color: #fff;
    margin: auto;
    border-radius: 50%;
    transition: background .2s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-transition: background .2s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -moz-transition: background .2s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -ms-transition: background .2s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -o-transition: background .2s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.pastEventButton:hover {
    background-color: #090938;
    background-image: 
        radial-gradient(circle at 120% 120%, #d4883c 0%, #b23641 15%, #090938 50%, transparent 50%, transparent ), 
        radial-gradient(circle at -20% -20%, #1648bd 0%, #090938 50%, transparent 50%, transparent );
    border-color: #cad4e0;
}

.pastEventButton:active {
    /* background: linear-gradient(to right, #dfae89, #18c0e6); */
    border-color: #cad4e0;
    background-color: #090938;
    background-image: 
        radial-gradient(circle at 120% 120%, #d4883c 0%, #b23641 15%, #090938 50%, transparent 50%, transparent ), 
        radial-gradient(circle at -20% -20%, #1648bd 0%, #090938 50%, transparent 50%, transparent );
}

.pastEventButton:focus {
    /* background: linear-gradient(to right, #dfae89, #18c0e6); */
    border-color: #cad4e0;
    box-shadow: 0 0 0 0.25rem rgb(202 212 224 / 50%);
    background-color: #090938;
    background-image: 
        radial-gradient(circle at 120% 120%, #d4883c 0%, #b23641 15%, #090938 50%, transparent 50%, transparent ), 
        radial-gradient(circle at -20% -20%, #1648bd 0%, #090938 50%, transparent 50%, transparent );
}

.pastEventButton:active:focus {
    box-shadow: 0 0 0 0.25rem rgb(202 212 224 / 50%);
    background-color: #090938;
    background-image: 
        radial-gradient(circle at 120% 120%, #d4883c 0%, #b23641 15%, #090938 50%, transparent 50%, transparent ), 
        radial-gradient(circle at -20% -20%, #1648bd 0%, #090938 50%, transparent 50%, transparent );
}

.past-event-title {
    margin-left: 30px;
}

/* #background {
    position: fixed;
    width: 100%;
    height: 100%;
    background:
        radial-gradient(circle at 80% 100%,#dfae89 0%, #0e0e56 35%, transparent 100%),
        radial-gradient(circle at 30% 8%, #18c0e6 0%, #18c0e6 5%,  #0e0e56 40%, transparent 100%)
    ;
    background-repeat: no-repeat;
    background-size: stretch;
    background-color: #0e0e56;
    color: white;
} */

#linear-gradient-background {
        min-height: 100vh;
        /* background:
        radial-gradient( circle at 100% 0%, #b54850 0%, #1e1233 90% ) 
        background: rgb(30,18,51);
        background: linear-gradient(16deg, rgba(30,18,51,1) 0%, rgba(181,72,80,1) 100%);
         */
    background-color: #090938;
    background-image: 
    radial-gradient(circle at 100% 100%, #d4883c 0%, #b23641 15%, #090938 50%, transparent 50%, transparent ), 
    radial-gradient(circle at 0% 0%, #1648bd 0%, #090938 50%, transparent 50%, transparent );

}

/* #picture-background {
    background-image: url("../../assets/backgroundWithLines.jpg");
    background-size: cover;
} */

/* #scroll {
    height: 100%;
    overflow: auto;
    position: relative;
} */

#fullHeight {
    /* min-height: calc(100vh - 286px); */
    flex-grow: 1;
}

p {
    margin: 0;
    padding: 0;
    color: white;
}

.nav-logo {
    margin-bottom: 6px;
}

.nav-adjust {
    margin-top: 4px;
}

.band-image {
    width: 190px;
    height: 190px;
    border-radius: 70%;
    -webkit-border-radius: 70%;
    -moz-border-radius: 70%;
    -ms-border-radius: 70%;
    -o-border-radius: 70%;
    border: solid 1px black;
}


/* .band-card h2 {
    color: #02005e;
} */

.band-image-2 {
    object-fit: cover;
    height: 64px;
    width: 64px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.icon {
    fill: white;
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
}

.icon-text {
    margin-top: -9px;
}

.icon-button {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    color: white;
    fill: white;
    transition: none;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
}

.icon-button:hover {
    color: rgba(255, 255, 255, 0.5);
    fill:rgba(255, 255, 255, 0.5);
}

.icon-button:focus {
    box-shadow: none;
}

.voted-icon {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
}

.winners-text {
    font-size: 0.9em;
    font-weight: 700;
}

.voteButton {
    z-index: 10;
}

@media screen and (max-width: 767px) {
    .md-hidden {
        display: none !important;
    }
}

@media screen and (min-width: 767px) {
    .band-row:hover {
        background-color: rgba(255, 255, 255, 0.25);
        border-radius: 5px;
        cursor: pointer;
        z-index: 1;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
    }
}

.rtl {
    direction: rtl;
}

.container {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.video-player {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
}

.video-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
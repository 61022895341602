.fullHeight {
    flex-grow: 1;
}

#hero-section {
    background-size: cover;
    /*background:
        radial-gradient(circle at 100% 100%,#CC783A 0%, #A24445 15%, #A24445 30%, rgba(14,14,86,0.7) 50% ),
        radial-gradient(circle at 5% 5%, #18c0e6 0%, #0e0e56 20%, #0e0e56 30%, rgba(14, 14, 86, 0.7) )
    ;*/
    background-color: #090938;
    background-image: 
    radial-gradient(circle at 100% 100%, #d4883c 0%, #b23641 15%, #090938 50%, transparent 50%, transparent ), 
    radial-gradient(circle at 0% 0%, #1648bd 0%, #090938 50%, transparent 50%, transparent );
}

#current-section {
    background: rgb(30,18,51);
    background: linear-gradient(16deg, rgba(30,18,51,1) 0%, rgba(181,72,80,1) 100%);
}


/* #background {
    position: fixed;
    width: 100%;
    height: 100%;
    background:
        radial-gradient(circle at 80% 100%,#dfae89 0%, #0e0e56 35%, transparent 100%),
        radial-gradient(circle at 30% 8%, #18c0e6 0%, #18c0e6 5%,  #0e0e56 40%, transparent 100%)
    ;
    background-repeat: no-repeat;
    background-size: stretch;
    background-color: #0e0e56;
    color: white;
} */

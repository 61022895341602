.my-row {
    display: flex;
    flex-wrap: wrap;
    padding: 4px;
  }


  .my-column {
    flex: 25%;
    max-width: 50%;
    padding: 0 4px;
  }

  .my-column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    .my-column {
      flex: 100%;
      max-width: 100%;
    }
  }
